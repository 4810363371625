<template>
  <div>
    <!-- 授权激活 -->
    <el-form
      :model="tableData"
      ref="tableData"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
      style="margin-top: 25px; margin-left: 20px"
    >
      <el-form-item
        :label="$t('System.LicensePage.requestCode')"
        prop="requestCode"
      >
        <span>{{ tableData.requestCode }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.LicensePage.serialNumber')" prop="sn">
        <el-input
          size="small"
          v-model="tableData.sn"
          style="width: 500px; margin-right: 10px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('System.LicensePage.ActivationCode')"
        prop="responseCode"
      >
        <el-input
          size="small"
          v-model="tableData.responseCode"
          style="width: 500px; margin-right: 10px;'"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <span style="color: #d9001b">{{
          $t("System.LicensePage.ContactCustomerService")
        }}</span>
      </el-form-item>
      <el-form-item>
        <el-button
          style="width: 80px"
          size="small"
          type="primary"
          @click="saveForm"
          >{{ $t("System.LicensePage.activation") }}</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 保存成功提示组件 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>
<script>
import Nav from "@/components/hearder";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { Nav, dialogInfo },
  data() {
    return {
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      tableData: {},
      rules: {
        sn: [
          {
            required: true,
            message: this.$t("System.LicensePage.serialNumberVoid"),
            trigger: "blur",
          },
        ],
        responseCode: [
          {
            required: true,
            message: this.$t("System.LicensePage.ActivationCodeVoid"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.getDataList();
  },
  methods: {
    // 获取授权信息
    async getDataList() {
      var parma = {};
      const res = await this.$axios.post(
        "/httpServe/license/selectOne",
        parma,
        true
      );
      console.log(res);
      this.tableData = res.data;
    },
    // 激活
    saveForm() {
      this.$refs.tableData.validate(async (valid) => {
        if (valid) {
          const res = await this.$axios.post(
            "/httpServe/license/active",
            this.tableData,
            true
          );
          console.log(res);
          if (res.data == -3) {
            this.$message({
              message: this.$t("System.LicensePage.ActivationFailed"),
              type: "error",
              offset: 100,
            });
          } else if (res.data == 1) {
            this.$message({
              message: this.$t("System.LicensePage.ActivationSuccessful"),
              type: "success",
              offset: 100,
            });
          }
        } else {
          console.log("error");
        }
      });
    },
    // 关闭提示框
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>